import '@b-stock/bstock-next/app-nonce'

import { useEffect, useCallback } from 'react'
import type * as React from 'react'

import type { StatusType } from '@datadog/browser-logs'
import dynamic from 'next/dynamic'

import type { BStockAppProps } from '@b-stock/bstock-next'
import {
  BStockApp,
  decodeAccessToken,
  useAccessToken,
} from '@b-stock/bstock-next'
import { ddLogger, ErrorBoundary } from '@b-stock/bstock-react'

import { PageTitle } from '@components/PageTitle'
import Pendo from '@components/Pendo'
import { FeatureFlagProvider } from '@helpers/featureFlags'
import getRuntimeConfig from '@helpers/getRuntimeConfig'
import { useLogError } from '@helpers/useLogError'

import { localeData } from '../locale/localeData'
import '../locale/setYupLocale'

import '@b-stock/bstock-react/styles/global.css'
import 'react-toastify/dist/ReactToastify.min.css'

const { APP_ENV, DATADOG_LOGS, DATADOG_CLIENT_TOKEN, LOGGER_LEVEL, BUILD_ID } =
  getRuntimeConfig()

const DynamicDevTools = dynamic(() => import('./DevTools'), {
  loading: () => null,
})

if (typeof window !== 'undefined') {
  ddLogger.startLogger({
    datadogConfig: {
      clientToken: DATADOG_CLIENT_TOKEN,
      version: BUILD_ID,
      forwardErrorsToLogs: true,
      forwardConsoleLogs: ['error'],
      sessionSampleRate: 100,
    },
    level: LOGGER_LEVEL as StatusType,
    env: APP_ENV || '',
    enabled: DATADOG_LOGS,
    appName: 'buyer-portal',
  })
}

const BuyerApp = (
  appProps: Omit<
    BStockAppProps,
    'theme' | 'locale' | 'localeData' | 'showLoadingSpinner'
  >
) => {
  const {
    Component,
    pageProps: { featureFlags, launchDarklyClientSideId },
  } = appProps
  const accessToken = useAccessToken()

  const logError = useLogError()

  // Memoize logError to avoid unnecessary re-renders of WrappedComponent
  const memoizedLogError = useCallback(
    (error: Error, errorInfo: React.ErrorInfo) => {
      logError(error, errorInfo)
    },

    // NOTE: Intentional empty dependency, if removed breaking the auction category filter
    // eslint-disable-next-line react-compiler/react-compiler
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  useEffect(() => {
    if (accessToken) {
      const { accountId, personId } = decodeAccessToken(accessToken)
      if (accountId && personId) {
        ddLogger.setGlobalContext({ token: { accountId, personId } })
      }
    }
  }, [accessToken])

  const WrappedComponent = useCallback(
    (
      pageProps: React.JSX.IntrinsicAttributes & { children?: React.ReactNode }
    ) => (
      <>
        <PageTitle />
        <FeatureFlagProvider
          featureFlags={featureFlags}
          clientSideId={launchDarklyClientSideId}
        >
          <ErrorBoundary onError={memoizedLogError}>
            <Component {...pageProps} />
          </ErrorBoundary>
        </FeatureFlagProvider>
        <Pendo apiKey="cde87dbc-c4f3-4439-57d1-4d143cfe94e4" />
        {APP_ENV === 'dev' || APP_ENV === 'qa' ? <DynamicDevTools /> : null}
      </>
    ),
    [Component, featureFlags, launchDarklyClientSideId, memoizedLogError]
  )

  return (
    <BStockApp
      {...appProps}
      locale={'en'}
      localeData={localeData}
      showLoadingSpinner
      Component={WrappedComponent}
    />
  )
}

export default BuyerApp
