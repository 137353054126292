import Head from 'next/head'
import { useIntl } from 'react-intl'

/**
 * Pass a string or array of strings/keys. Keys will be localized. If you need
 * a localized string with values, do it yourself and pass the resulting string.
 *
 * Note: we just have <PageTitle> vs something like <PageMeta> because we have
 * no SEO traffic or social media sharing, etc. Thus no description, canonical
 * URL, image, video, etc. The business is not and will never be public-facing.
 *
 * We put "B-Stock" first and do not truncate because browsers show different
 * amounts of the title in different contexts.
 *
 * In Chrome:
 * - Back button menu (click and hold) shows 92 + ellipsis
 * - Hovering over a tab title shows 58 + ellipsis
 * - Unhovered, unshrunk tab title shows 25, fading out
 *
 * @usage
 *   1. in _app.page.ts, add default `<PageTitle />` (just "B-Stock")
 *
 *   2. override the default page title in each page.
 *
 *   `<PageTitle value="foo" />` renders "B-Stock | foo"
 *
 *   `<PageTitle value={['PageTitle.listing', lot.title]} />`
 *   might render "B-Stock | Listing | Fancy Lot with widgets and wodgets"
 *
 * @todo Migrate to bstock-next
 */
export const PageTitle = ({
  value: input,
}: {
  value?: string | null | Array<string | undefined | null>
}) => {
  const { formatMessage, messages } = useIntl()

  const maybeKeys = (
    input ? (Array.isArray(input) ? input : [input]) : []
  ).filter(Boolean) as string[]

  const parts = maybeKeys.map((x) =>
    messages[x] ? formatMessage({ id: x }) : x
  )

  parts.unshift('B-Stock') // OK to hard-code

  return (
    <Head>
      <title key="page-title">{parts.join(' | ')}</title>
    </Head>
  )
}
