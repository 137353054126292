import Auction from './Auction.json'
import BuyNow from './BuyNow.json'
import Common from './Common.json'
import Contract from './Contract.json'
import Contracts from './Contracts'
import Dispute from './Dispute.json'
import Listing from './Listing.json'
import Listings from './Listings'
import Offerings from './Offerings'
import Orders from './Orders'
import PageTitle from './PageTitle.json'
import unsorted from './unsorted.json'

export default {
  Auction,
  BuyNow,
  Common,
  Contract,
  Contracts,
  Dispute,
  Listing,
  Listings,
  Offerings,
  Orders,
  PageTitle,
  ...unsorted,
} as const
